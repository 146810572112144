import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import React, { useEffect, useState } from "react";
import {
  CosmicSupportedCurrency,
  CosmicSupportedLocale,
  cosmicSupportedLocales,
  useInitializeCosmicLocalizationContext,
} from "@taxbit-private/cosmic-localization";
import { useGetTenantMetadata } from "./api/endpoints/tenants/tenantMetadataGetApi";
import PositionedSpinner from "./components/Spinner";
import {
  cosmicSupportedCurrencySchema,
  cosmicSupportedLocaleSchema,
} from "./api/endpoints/tenants/tenantMetadataTypes";

type I18nWrapperProps = { children: React.ReactNode };

const isSupportedLocale = (locale: string): locale is CosmicSupportedLocale => {
  return cosmicSupportedLocaleSchema.safeParse(locale).success;
};

const isSupportedCurrency = (
  currency: string
): currency is CosmicSupportedCurrency => {
  return cosmicSupportedCurrencySchema.safeParse(currency).success;
};

const I18nWrapper: React.FC<I18nWrapperProps> = ({ children }) => {
  const { data: tenant, isLoading, isError } = useGetTenantMetadata();
  const { setLocalizationContext, isCosmicLocalizationInitialized } =
    useInitializeCosmicLocalizationContext();
  const [initDone, setInitDone] = useState(false);

  useEffect(() => {
    if (!isLoading) {
      // Default to en-US if there is an error or unsupported locale
      const locale =
        isError || !isSupportedLocale(tenant?.locale)
          ? cosmicSupportedLocaleSchema.Enum["en-US"]
          : tenant.locale;

      const currency =
        isError || !isSupportedCurrency(tenant?.preferredFiatAsset.code)
          ? cosmicSupportedCurrencySchema.Enum.USD
          : tenant.preferredFiatAsset.code;

      setLocalizationContext({
        locale,
        currency,
      });

      void i18n
        .use(initReactI18next)
        .use(Backend)
        .init({
          lng: locale,
          fallbackLng: "en-US",
          supportedLngs: cosmicSupportedLocales,
          ns: ["case", "transactions", "sources", "reports"],
          interpolation: {
            escapeValue: false, // react already safes from xss
          },
        })
        .then(() => {
          setInitDone(true);
        });
    }
  }, [isLoading, isError, tenant, setLocalizationContext]);

  if (isLoading || !initDone || !isCosmicLocalizationInitialized) {
    return <PositionedSpinner />;
  }

  return <>{children}</>;
};

export default I18nWrapper;
