import { createContext, useState, useContext } from "react";

type CaseFilterQueryParamsCacheProviderProps = {
  children: React.ReactNode;
};

type CaseFilterQueryParamsCacheType = {
  caseFilterQueryParamsCache: string;
  updateCaseFilterQueryParamsCache: (queryString: string) => void;
};

const CaseFilterQueryParamsCache =
  createContext<CaseFilterQueryParamsCacheType>({
    caseFilterQueryParamsCache: "",
    updateCaseFilterQueryParamsCache: () => {},
  });

/** Allows the Case Filter state to be persisted in the URL when the
 * user navigates away from the page and returns.
 * Ex: User clicks on a case, then clicks "Back to all cases" button.
 */

const CaseFilterQueryParamsCacheProvider: React.FC<
  CaseFilterQueryParamsCacheProviderProps
> = ({ children }) => {
  const [caseFilterQueryParamsCache, setCaseFilterQueryParamsCache] =
    useState("");

  const updateCaseFilterQueryParamsCache = (newQueryString: string) =>
    setCaseFilterQueryParamsCache(newQueryString);

  return (
    <CaseFilterQueryParamsCache.Provider
      value={{
        caseFilterQueryParamsCache,
        updateCaseFilterQueryParamsCache,
      }}
    >
      {children}
    </CaseFilterQueryParamsCache.Provider>
  );
};

export const useCaseFilterQueryParamContext = () =>
  useContext(CaseFilterQueryParamsCache);

export default CaseFilterQueryParamsCacheProvider;
