import { StateCreator } from "zustand";
import { Case } from "../api/endpoints/cases/caseTypes";
import { BannerConfig, BannerSlice } from "./bannerSliceModels";

const createBannerSlice: StateCreator<BannerSlice> = (set): BannerSlice => ({
  banners: {},
  setBanner: (caseId: Case["id"], banner: BannerConfig) =>
    set((state) => ({
      ...state,
      banners: {
        ...state.banners,
        [caseId]: banner,
      },
    })),
  removeBanner: (caseId: Case["id"]) =>
    set((state) => {
      const { [caseId]: _, ...banners } = state.banners;
      return { ...state, banners };
    }),
  clearBanners: () =>
    set((state) => ({
      ...state,
      banners: {},
    })),
});

export default createBannerSlice;
