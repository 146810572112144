import { Dispatch, SetStateAction, useEffect, useState } from "react";
import storage from "../storage";

type ConvertFromString = <T>(value: string) => T;

const getInitialValue = <T>(key: string, defaultValue: T, convertFromString: ConvertFromString): T => {
  const localStorageValue = storage.local.getItem(key);

  if (localStorageValue) {
    try {
        return convertFromString(localStorageValue);
    } catch {
        storage.local.removeItem(key);
    }
  }
  return defaultValue;
};

const useLocalStorageState = <T>(
  key: string,
  defaultValue: T,
  { convertToString = (value: T) => JSON.stringify(value), convertFromString = JSON.parse } = {}
): [T, Dispatch<SetStateAction<T>>] => {
  const [state, setState] = useState<T>(() => getInitialValue<T>(key, defaultValue, convertFromString));

  useEffect(() => {
    storage.local.setItem(key, convertToString(state));
  }, [key, state, convertToString]);

  return [state, setState];
};

export default useLocalStorageState;