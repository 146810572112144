import {
  Button,
  Flex,
  getMediaQueryForBelowBreakpoint,
  useIsWindowSizeBelowBreakpoint,
} from "@taxbit-private/cosmic";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import TaxbitLogoSvg from "../assets/header.svg";
import { useAuthentication } from "../auth/useAuthentication";
import useDartsStore from "../store/useDartsStore";
import { usePageTitle } from "./PageTitleUpdater";
import AppNotifications from "./notifications/AppNotifications";
import { useCaseFilterQueryParamContext } from "../utils/CaseFilterQueryParamsCacheProvider";

const TOP_HEADER_COLOR = "#052854";

const Shell: React.FC = () => {
  usePageTitle();
  const location = useLocation();
  const toggleMenu = useDartsStore((state) => state.toggleMenu);
  const clearToasts = useDartsStore((state) => state.clearToasts);
  const isMobile = useIsWindowSizeBelowBreakpoint("tablet");
  const { caseFilterQueryParamsCache } = useCaseFilterQueryParamContext();
  const navigate = useNavigate();

  const { signOut } = useAuthentication();

  const { t } = useTranslation();

  return (
    <Flex direction="column">
      <HeaderContainer>
        {location.pathname === "/case-list" ? (
          <div />
        ) : (
          <Wrapper>
            <Button
              onClick={() => {
                clearToasts();
                navigate(`/case-list${caseFilterQueryParamsCache}`);
              }}
              trackingId="back-to-all-cases"
              label={
                isMobile ? t("All") : t("Back to All Cases", { ns: "case" })
              }
              variant="invisible"
              iconName="arrow-left"
            />
          </Wrapper>
        )}
        <StyledImg src={TaxbitLogoSvg} alt="Taxbit Logo" />
        <Flex gap="l">
          {location.pathname !== "/case-list" && (
            <MenuButton
              iconName="menu"
              variant="invisible"
              trackingId="open-close-navigation"
              onClick={toggleMenu}
            />
          )}
          <LogOutButton
            onClick={signOut}
            iconName="log-out"
            variant="invisible"
            trackingId="logout-button"
          />
        </Flex>
      </HeaderContainer>
      <AppNotifications />
      <Outlet />
    </Flex>
  );
};

export default Shell;

const MenuButton = styled(Button)(
  ({ theme }) => `
    color: ${theme.color.white};
    display: none;
   ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
      display: flex;
      min-width: 16px;
      min-height: 16px;
  }
`
);

const LogOutButton = styled(Button)(
  ({ theme }) => `
  color: ${theme.color.white};
   ${getMediaQueryForBelowBreakpoint(theme, "tablet")} {
      min-width: 16px;
      min-height: 16px;
  }
`
);

const Wrapper = styled(Flex)(
  ({ theme }) => `
  color: ${theme.color.white};
`
);

const HeaderContainer = styled(Flex)(
  ({ theme }) => `
  background: ${TOP_HEADER_COLOR};
  justify-content: space-between;
  align-items: center;
  height: ${theme.sizing.xl};
  padding: ${theme.spacing.m};
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 3;
  min-width: calc(100% - 2 * (${theme.spacing.l} + ${theme.spacing.m}));
`
);

const StyledImg = styled.img(
  ({ theme }) => `
  height: ${theme.sizing.s}
`
);
