import { StateCreator } from "zustand";
import { highContrastTheme } from "@taxbit-private/cosmic";
import { MenuSlice } from "./menuSliceModel";

const createMenuSlice: StateCreator<MenuSlice> = (set) => ({
  /* set inital value for isMenuVisible based on if we are in mobile viewport */
  isMenuVisible: !(
    window.innerWidth <
    Number.parseInt(String(highContrastTheme.breakpoint.tablet), 10)
  ),
  toggleMenu: () =>
    set((state) => {
      return { ...state, isMenuVisible: !state.isMenuVisible };
    }),
});

export default createMenuSlice;
