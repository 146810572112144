import { datadogRum } from "@datadog/browser-rum";
import { isCommercialEnvironment } from "../utils/environment";

/**
 * Don't run Datadog in development mode (i.e. npm run start)
 */
const shouldInitializeDatadog = () => {
  return import.meta.env.MODE !== "development" && isCommercialEnvironment();
};

export const initializeDatadog = () => {
  if (shouldInitializeDatadog()) {
    datadogRum.init({
      applicationId: import.meta.env.VITE_DATADOG_APPLICATION_ID,
      clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
      site: "datadoghq.com",
      service: "darts",
      env: import.meta.env.VITE_REACT_APP_ENVIRONMENT,
      version: window.appVersion,
      trackInteractions: true,
      defaultPrivacyLevel: "mask-user-input",
      sessionSampleRate: 100,
      trackLongTasks: true,
      trackResources: true,
    });
  }
};
