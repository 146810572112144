import { create } from "zustand";
import createBannerSlice from "./bannerSlice";
import createToastsSlice from "./toastSlice";
import createMenuSlice from "./menuSlice";
import { BannerSlice } from "./bannerSliceModels";
import { ToastSlice } from "./toastSliceModels";
import { MenuSlice } from "./menuSliceModel";

type DartsStoreState = ToastSlice & BannerSlice & MenuSlice;

/**
 * This react hook utilizes zustand, and should be used in our application
 * in scenarios where global synchronous state needs to be managed
 */
const useDartsStore = create<DartsStoreState>()((...state) => ({
  ...createToastsSlice(...state),
  ...createBannerSlice(...state),
  ...createMenuSlice(...state),
}));

export default useDartsStore;
