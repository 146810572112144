import React from "react";

// The exception to the eslint no-restricted-import rule
// eslint-disable-next-line no-restricted-imports
import { Flex, InlineSpinner, Spinner } from "@taxbit-private/cosmic";

const PositionedSpinner: React.FC = () => {
  return (
    <Flex padding="xxl" justifyContent="center">
      <Spinner />
    </Flex>
  );
};

export const PositionedInlineSpinner: React.FC = () => {
  return (
    <Flex alignItems="center" justifyContent="center">
      <InlineSpinner />
    </Flex>
  );
};

export default PositionedSpinner;
