import { StateCreator } from "zustand";
import { v4 as uuidv4 } from "uuid";
import { ToastSlice, ToastConfig, ToastUuid, ToastType } from "./toastSliceModels";

const createToastsSlice: StateCreator<ToastSlice> = (set): ToastSlice => ({
  toasts: [],
  addToast: (toast: ToastConfig) => {
    set(({ toasts }) => ({
      toasts: [
        ...toasts,
        {
          uuid: uuidv4() as ToastUuid,
          variant: "primary",
          ...toast,
        },
      ],
    }));
  },
  removeToast: (uuidToRemove: string) =>
    set(({ toasts }) => ({
      toasts: toasts.filter(({ uuid }) => uuid !== uuidToRemove),
    })),
  clearToasts: (toastType: ToastType = ToastType.ALL) => {
    if (toastType === ToastType.ALL) {
      set(() => ({ toasts: [] }))
    } else {
      set(({ toasts }) => ({
        toasts: toasts.filter(({ type }) => {
          return !type || type !== toastType;
        }),
      }));
    }
  },
});

export default createToastsSlice;
