import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { z } from "zod";

const subpoenaSchema = z.object({
  label: z.string(),
  subpoena_type: z.string(),
});

export type AddSubpoenaForm = z.infer<typeof subpoenaSchema>;

export enum FileRequestSourceType {
  SUBPOENA_INTERNAL = "subpoena-internal",
  JSONL = "jsonl",
  FORCED_MATCHING = "forced-matching",
  WALLET = "wallet",
}

const useAlphanumericString = () => {
  const { t } = useTranslation("sources");

  return useMemo(
    () =>
      z.string().superRefine((val, ctx) => {
        if (!val.trim()) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("AccountNameRequiredMsg"),
          });
        }
        const regex = /^[a-zA-Z0-9 _-]+$/;
        if (!regex.test(val)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: t("ValidAccountNameMsg"),
          });
        }
      }),
    [t]
  );
};

export const useAddSubpoenaFileRequestSchema = () => {
  const alphanumericString = useAlphanumericString();

  return useMemo(
    () =>
      z.object({
        fileName: z.string().optional(),
        source: z.string().optional(),
        sourceType: z.nativeEnum(FileRequestSourceType),
        subpoenaType: z.string().optional(),
        label: alphanumericString.optional(),
        integrationId: z.string().optional(),
      }),
    [alphanumericString]
  );
};

export type AddSubpoenaFileRequest = z.infer<
  ReturnType<typeof useAddSubpoenaFileRequestSchema>
>;

export type SubSource = {
  name: string;
  type: string;
};

export const useGenericCsvSchema = () => {
  const alphanumericString = useAlphanumericString();

  return useMemo(
    () =>
      z.object({
        label: alphanumericString,
      }),
    [alphanumericString]
  );
};

export type AddGenericCsvForm = z.infer<ReturnType<typeof useGenericCsvSchema>>;

export const useJsonLFileSchema = () => {
  const alphanumericString = useAlphanumericString();

  return useMemo(
    () =>
      z.object({
        label: alphanumericString,
        walletAddress: z.string().optional(),
      }),
    [alphanumericString]
  );
};

export const usePublicAddressSchema = () => {
  const alphanumericString = useAlphanumericString();

  return useMemo(
    () =>
      z.object({
        walletAddress: z.string(),
        source: z.string(),
        label: alphanumericString,
        integrationId: z.string(),
        sourceType: z.nativeEnum(FileRequestSourceType),
      }),
    [alphanumericString]
  );
};

export type AddJsonLFileSchemaForm = z.infer<
  ReturnType<typeof useJsonLFileSchema>
>;

export type AddPublicAddressSchemaForm = z.infer<
  ReturnType<typeof usePublicAddressSchema>
>;
